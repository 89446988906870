<template>
  <div class="dashboard">
    <v-row
    >
      <v-col lg="1" cols="12">
        <a
            href="/"
        ><v-img
            :src="require('../assets/Bouton retour.png')"
            contain
            max-height="34"
        /></a>
      </v-col>
    </v-row>
    <v-row>
      <v-col md="4">
        <v-img
            :src="require('../assets/monfiveaday/Plan de travail 34@2x-100.jpg')"
            contain
            max-height="135"
        />
      </v-col>
      <v-col
          md="4"
          mt
          offset-md="4"
      >
        <v-img
            :src="require('../assets/monfiveaday/Plan de travail 16@2x-100.jpg')"
            contain
            max-height="150"
            min-width="400"

        />
      </v-col>
    </v-row>
    <br>
    <v-row>
      <v-col lg="7" cols="12">
        <v-card
            class="mx-auto text-center"
            color="#14ad96"
            dark
            max-width="600"
        >
          <v-card-text>
            <v-sheet color="#288577">
              <v-sparkline
                  :value="value"
                  color="rgba(255, 255, 255, .7)"
                  height="100"
                  padding="24"
                  stroke-linecap="round"
                  smooth
              >
                <template v-slot:label="item">
                  {{ item.value }} €
                </template>
              </v-sparkline>
            </v-sheet>
          </v-card-text>

          <v-card-text>
            <div class="text-h4 font-weight-thin">
              Ventes du mois
            </div>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions class="justify-center">
            <v-btn
                block
                text
            >
              Voir le rapport
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" lg="5">
        <v-card>
          <v-card-title>
          <v-img
              :src="require('../assets/monfiveaday/H2-MonFAD2@2x-100.jpg')"
              contain
              max-height="73"
          />
          </v-card-title>
          <v-card-text class="py-0">
            <v-timeline align-top dense>
              <v-timeline-item color="indigo" small>
                <strong>Commande 4oBdlnzHvTua7NLtts9x livrée</strong>
                <div class="text-caption">
                  5 Minutes
                </div>
              </v-timeline-item>
              <v-timeline-item color="green" small>
                <strong>Commande TvPckMHY1x en cours de livraison</strong>
                <div class="text-caption mb-2">
                  35 Minutes
                </div>
              </v-timeline-item>

              <v-timeline-item color="indigo" small>
                <strong>Commande xgmm1R6RUCkJ2SfqXd1W en préparation</strong>
                <div class="text-caption">
                  44 Minutes
                </div>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>
            <v-img
                :src="require('../assets/monfiveaday/H2-MonFAD@2x-100.jpg')"
                contain
                max-height="55"
            />
          </v-card-title>
          <v-simple-table>
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Numéro
                </th>
                <th class="text-left">
                  Date
                </th>
                <th class="text-left">
                  Montant
                </th>
                <th>
                  Actions
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="order in orders" :key="order.id"
              >
                <td>{{ order.number }}</td>
                <td>{{ (order.date).toDate().toString() }}</td>
                <td>{{ order.total }}</td>
                <td>
                  <router-link to="/facture"><v-img
                      :src="require('../assets/monfiveaday/Plan de travail 35.svg')"
                      contain
                      max-height="25"
                  /></router-link>
                  <router-link :to="{name: 'Facture', params: {id : order.number}}">
                    <v-img
                        :src="require('../assets/monfiveaday/Plan de travail 35.svg')"
                        contain
                        max-height="25"
                    />
                  </router-link>
                </td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../main";
import {mapGetters} from "vuex";

export default {
  name: "Dashboard",
  data() {
    return {
      orders: [],
      value: [
        423,
        446,
        200,
        510,
        590,
        610,
        760,
      ],
      activityLog: [
        {title: 'Total Products', amount: 50, icon: 'mdi-account', color: 'cyan lighten-3'},
        {title: 'Total Customer', amount: 3433, icon: 'mdi-account-group-outline', color: 'green darken-2'},
        {title: 'Total Sale', amount: 3433, icon: 'mdi-account-group-outline', color: 'blue-grey darken-1'},
        {
          title: 'Pending Orders',
          amount: 3433,
          icon: 'mdi-account-group-outline',
          color: 'deep-orange darken-1'
        },
      ],
    }
  },
  computed : {
    ...mapGetters(["currentUser", "cart"]),
  },
  methods: {
    onButtonClick(item) {
      console.log('click on ' + item.no)
    },
    logout: function () {
      this.$store.commit("logout");
      this.$router.push("/");
    },
    async getOrders(){
      const q = query(collection(db, "commandes"), where("user", "==", this.currentUser.uid));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        console.log(doc.id, " => ", doc.data());
        this.orders.push(doc.data());
      });
    }
  },
  created() {
    this.getOrders();
  }
}
</script>

<style scoped>
.overlap-icon {
  position: absolute;
  top: -33px;
  text-align: center;
  padding-top: 12px;
}
.greg-stick{
  position: relative;
  bottom: 72%;
}
</style>